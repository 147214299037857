<template>
    <div>
      Временное меню:
      <router-link to="/operations-test2">Монитор операций тестовый</router-link> |
    </div>
    <div v-for="(sett) in settings" :key="sett.id" class="table-row grey">
      <div class="sett-name">{{ sett.name }}</div>
      <div class="sett-value"><input type="text" v-model="sett.val" /></div>
    </div>
    <div class="table-row">
      <div class="sett-name"></div>
      <div class="sett-value">
        <span v-if="loader" >Сохранение ...</span>
        <a v-else @click="saveSettings" class="btn-def">Сохранить</a>
      </div>
    </div>
  <div>
    <h2>Стоимость 1 минуты на узком месте</h2>
    <div v-for="(sect, index) in sectors" :key="sect.id" class="table-row grey">
      <div class="sett-name">{{ sect.name }}</div>
      <div class="sett-value">
        <input @change="saveCost(index)" type="text" v-model="sect.cost_one_minutes" /> р.
      </div>
    </div>
  </div>
  <div>
    <h2>Коэфицент повышения/ цены для зон</h2>
    <div v-for="(zone, index) in zones" :key="zone.id" class="table-row grey">
      <div class="sett-name">{{ zone.name }}</div>
      <div class="sett-value">
        <input @change="saveCostZone(index)" type="text" v-model="zone.price_cost" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Settings',
  data() {
    return {
      loader: false,
      settings: [],
      sectors: [],
      zones: [],
      errors: [],
    };
  },
  mounted() {
    this.getSettings();
    this.getSectors();
    this.getSpeedZones();
  },
  methods: {
    getSettings() {
      axios.get('api/show-settings')
        .then((res) => {
          this.settings = res.data.settings;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getSectors() {
      axios.get('db/sectors-cost')
        .then((res) => {
          this.sectors = res.data.sectors;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getSpeedZones() {
      axios.get('db/speed-zones-cost')
        .then((res) => {
          this.zones = res.data.zones;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    saveSettings() {
      this.loader = true;
      axios.post('api/save-settings', { form: this.settings })
        .then((res) => {
          if (res.data.success === 1) {
            console.log('ok');
          } else {
            console.log('no ok');
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    saveCost(index) {
      axios.post('db/save-sector-cost', { id: this.sectors[index].id, val: this.sectors[index].cost_one_minutes })
        .then((res) => {
          if (res.data.success === 1) {
            console.log('ok');
          } else {
            console.log('no ok');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    saveCostZone(index) {
      axios.post('db/save-speed-zones-cost', { id: this.zones[index].id, val: this.zones[index].price_cost })
        .then((res) => {
          if (res.data.success === 1) {
            console.log('ok');
          } else {
            console.log('no ok');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
  .table-row{display: flex; flex-direction: row; justify-content: left; align-items:center;
  margin-bottom:12px; padding-left:12px;}
  .table-row.grey{ background: #aac4e3; }

  .btn-def{background: #46b446; cursor:pointer; padding: 10px 18px;
    color: #fff;
    font-size: 14px;
    display:block;
    width: 170px;
    text-align: center;
    border-radius:4px;
    border:1px solid #dde7f3;
  }
  .btn-def:hover{background: #47d23f }
  .sett-name{flex:2;}
  .sett-value{flex:1; }
  input{padding: 10px 8px;
    outline: none;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    width: 170px;
  }
</style>
